/* Menu styling */
header {
    position: relative
}
body.fixed {
    border-top: var(--fixed-header-height) solid transparent
}
body.fixed header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    min-height: var(--fixed-header-height)
}
nav {
    background: inherit;
}
ul.menu,
ul.menu li,
ul.sub-menu-items {
    margin: 0;
    padding: 0;
    color: inherit;
    background: inherit
}
ul.menu li {
    padding: 0 .75em;
    display: inline-block;
}
ul.menu li a {
    display: inline-block;
    padding: .4em 0;
}

/* Dropdown menu */
ul.menu li.dropdown {
    position: relative
}
li.dropdown:hover > ul.sub-menu-items {
    display: inline;
}
ul.sub-menu-items {
    display: none;
    white-space: normal;
    position: absolute;
    top: 100%;
    left: 0;
    padding-bottom: .5em;
    background: inherit;
    margin-left: -.5em
}
ul.menu > li.last > ul.sub-menu-items {
    left: auto;
    right: 0;
    text-align: right;
    margin-right: -.5em
}
ul.sub-menu-items li {
    float: none;
    white-space: nowrap
}
ul.sub-menu-items li a {
    min-width: 200px;
    float: none;
    color: inherit;
    display: block;
    padding: 0.4em .5em;
}
ul.sub-menu-items ul.sub-menu-items {
    position: absolute;
    transform: none;
    left: 100%;
    top: 0;
    margin: 0
}
#menu-icon {
    display: none
}
#menu-icon span,
#menu-icon:after,
#menu-icon:before {
    border: 2px solid;
    width: 30px;
    margin-bottom: 5px;
    display: block;
    transition: all .1s;
    content: ""
}

/*Menu breakpoint*/
@media (max-width: var(--breakpoint-menu)) {
    header .menu {
        top: 100%
    }
    ul.menu,
    ul.menu.show {
        transition: max-height .5s linear
    }
    ul.menu {
        margin: 0;
        padding: 0 1em;
        display: block;
        max-height: 0;
        position: absolute;
        overflow: hidden;
        left: 0;
        right: 0;
        background: inherit;
        z-index: 1000
    }
    ul.menu.show {
        overflow-y: auto;
        max-height: calc(100vh - var(--fixed-header-height));
    }
    ul.menu,
    ul.menu li,
    ul.menu a,
    ul.sub-menu-items,
    ul.sub-menu-items li,
    ul.sub-menu-items li a {
        width: 100%
    }
    ul.menu.menu li {
        display: block;
        margin: 0;
        padding:0;
    }
    ul.menu > li.first {
        padding-top: 0.5em;
    }
    ul.menu > li.last {
        padding-bottom: 0.5em;
    }
    ul.menu.menu li a {
        padding: .25em 0;
    }
    ul.sub-menu-items,
    ul.menu > li.last > ul.sub-menu-items,
    li.dropdown:hover ul.sub-menu-items,
    ul.sub-menu-items ul.sub-menu-items {
        position: relative;
        left: 0;
        right: auto;
        text-align: left;
        display: block;
        padding: 0 1em;
        margin: 0;
     }
    #menu-icon {
        display: inline-block;
        cursor: pointer;
        flex-grow: 0;
        padding: 10px;
        z-index: 4
    }
    #menu-icon.active:before {
        transform: rotate(45deg);
        margin-top: 10px
    }
    #menu-icon.active span {
        transform: rotate(135deg);
        position: relative;
        top: -9px
    }
    #menu-icon:after {
        margin-bottom: 0
    }
    #menu-icon.active:after {
        display: none
    }
}
